#Header4Container{
    width: 100%;
    min-height: 50px;
    background-color: #444140;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
}
#Header7Container{
    width: 100%;
    min-height: 50px;
    background-color: #444140;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color:white;
}
.notificaciones{
    width: 90%;
    text-align: center;
}
.botonHeader7{
    border: 0;
    background-color: transparent;
    width: 45px;
    height: 45px;
    background-image: url(/Images/Atrás.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
}
.buttonClass{
    width: 150px;
    height: 35px;
    margin: 0 0 0 20px;
}
.buttonClass:hover{
    background-color: #FFA988;
    color: black;
}
@media(max-width: 600px){
    .botonHeader7{
        width: 40px;
        height: 40px;
    }
}