.CursoIC {
    padding: 5px;
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.CursoIC2 {
    width: 80%;
    flex-wrap: wrap-reverse;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.Filtros{
   
    margin-top: 10px;
    width: 100%;
    min-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.SearchLogo{
    width: 35px;
}
.FiltrosC2{
    background-image: url(/Images/SearchBlack.png);
    background-size: 40px;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: center;
    padding-left: 40px;
    margin: 2px 0 2px 0;
    width: 40%;
    height: 35px;
    cursor:text !important;
}
.FiltrosC{
    margin: 2px 0 2px 0;
    width: 156px;
    height: 34px;
}
.Filtros2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 60%;
    min-height: 40px;
    border-radius: 10px;
    background-color: #444140;
}
.CardCI {
    min-height: calc(90vh - 90px - 2vw);
    margin-bottom: 10px;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 60%;
}

.ImgCI {
    border: 1px solid #444140;
    box-shadow: 2px 2px 10px black;
    border-radius: 5px;
    width: 22vh;
    min-width: 100px;
    max-width: 15vw;

}

.TitlesI {
    margin: 2px;
}

.InfoContMini {
    width: 60%;
    margin: 5px;
    background-color: #F6EBE9;
    padding: 3px;
    border-radius: 5px;
}
.InfoContMinI2{
    border-radius: 10px 10px 0 0 ;
    padding:0.5px;
    background-color: #444140;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around
}
.cursorNone{
    cursor: default !important;
}
.blancoText{
    color:white;
}
.redText{
    color: #ff595e;
}
.orangeText{
    color: #ff924c;
}
.yellowText{
    color: #ffca3a;
}
.greenText{
    color: #8ac926;
}
.minicont{
    align-items: center;
    border-radius: 0 0 10px 10px;
    padding:0.5px;
    background-color: #444140;
    width: 100%;
    display: grid;
    grid-template-columns: 10% 80% 10%;
}
.gridComunidadTitle{
    grid-column: 2/3;
}
.gridComunidadVal{
    margin: 0;
    grid-column: 3/4;
}
.TitleC {
    color: white;
    text-align: center;
}
#BottonCI{

    display: flex;
    align-items: center;
margin-bottom: 3px;
}
.buttonI {
    margin-left: 4px;
    height: 35px;
}
#MaxContC{
    margin-top: 2vw;
    width: 100%;
    background-color: #7c7c7c;
    border-radius: 10px;
    box-shadow: 5px 5px 20px black;
}
.InfoCursoI {
    display: flex;
    flex-flow: column;
    align-items: center;
}
#Paginacion, #PaginacionI, #Paginacion2{
    margin-top: 5px;
    width: 100%;
    height: 40px;
    flex-flow: row;
    display: flex;
    justify-content: center;
    align-items: center;
}
.botonescamb, .botonescamb2{
    border-radius: 5px;
    margin: 0 10px 0 10px;
    width: 30px !important;
    height: 30px;
    transition: all 700ms;
}
.botonescamb{
    color: white;
    border: 1px solid #444140 !important;
    background-color: #444140 !important;   
}
.botonescamb2{
    cursor: default;
    border: 1px solid #FFA987 !important;
    background-color: #FFA987 !important;
}
.botonescamb:hover{
    transition: all 700ms;
    color: black !important;
    border: 1px solid #FFA987 !important;
    background-color: #FFA987 !important;
}
.botonescamb:focus, .botonescamb2:focus{
    outline: none;
}
.ModalFondo{
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}
.ModalTitle{
    padding: 0 15px 0 15px;
    width: calc(100% - 30px);
    color: white;
    background-color: #444140;
    border-radius: 12px 12px 0 0;
}
.Modal1{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #7c7c7c;
    width: 600px;
    height: max-content;
    border-radius: 12px;
    padding-bottom: 15px;
}
.buttonModal1{
    width: 50%;
    height: 35px;
    margin-top: 15px;
}
.RangeNumber{
    margin-top: 15px;
    display: flex;
    width: 100%;
    justify-content: center;
}
.numberModal1{
    text-align: center;
    width: 30px;
    height: 30px;
    color: white;
    background-color: #444140;
    border: #444140;
}
.slider {
    margin-right: 10px;
    -webkit-appearance: none;
    width: 50%;
    height: 20px;
    background: linear-gradient(90deg, rgb(214, 214, 214) 60%, rgb(214, 214, 214) 60%);
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    border-radius: 12px;
    box-shadow: 0px 1px 10px 1px black;
}
.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
}
@media(max-width:700px) {
    .FiltrosC2{
        background-size: 30px;
        width: 80%;
        height: 30px;
    }
    .Filtros2{
        width: 95%;
    }
    .CardCI {
        width: 80%;
    }
    .Modal1{
        width: 90%;
    }
}

@media(max-width:525px) {
    .CardCI {
        width: 90%;
    }
}