#conatinerM{
 display:flex;
 flex-flow: column;
 align-items: center;
 justify-content: flex-start !important;
 background-color:#444140 ;
 height: 100%;
}
.linkm{
    text-align: center;
    width: 90% !important;
}
.lista{
    display: none;
    width: 90%;
    padding-inline-start: 0px;
    list-style:none;    
    text-align:right;        
    margin-block-start: 0px;    
}
.lista li{
    margin-left: 10%;
    width: 90%;
    text-align:right;
}
.lista li input{    
    width: 100%;
    height:32px !important;
    margin: 5px 0px 5px 0px;
}
.grupo{
    display:flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
}
#conatinerM input{
    font-size:22px;
    width: 100%;
    height:40px;
    margin: 10px 0px 10px 0px;
    background-color: #FFA988;
}

.esquina{
    display: none;
       position: absolute;
       top: 10px;
       left: 10px;
}
.tama{
    width: 50px;
    filter:invert(100%);
}
@media(max-width:620px){
    #gridP{
        display: grid;
        grid-template-rows:max-content max-content;
        grid-template-columns:90% 10%;
    }
    .esquina{
        display: block;
    }
    #MeP{
        grid-column: 1/2;
        width: 90%;
        height: calc(90vh - 2vw);
    }
    .blur{
        filter: blur(2px);
    }
    #MP{
        display: none;
    }
    #conatinerM{
        display:flex;
        flex-flow: column;
        align-items: center;
        background-color:#444140 ;
        height: 100%;
        justify-content: space-evenly;
       }
}