.Cargando{
    background-image: url("/images/Carga.gif");
    position: fixed;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 99999;
    width: 100%;
    height: 100vh;
    background-color: rgb(256,256,256);
}