.contM5 {
    min-height: calc(90vh - 2vw - 25px);
    display: flex;
    flex-flow: column;
    background-color: #F7EBE8;
}
.contM52 {
    min-height: calc(100vh - 71px);
    display: flex;
    flex-flow: column;
    background-color: #F7EBE8;
}
.franja {
    display: flex;
    justify-content: space-around;
    background-color: #F7EBE8;
    padding-block-start: 2vh;
    padding-block-end: 2vh;
}

.TitleCardClase {
    max-width: 60%;
    color: white;
    text-align: center;
}
.botoclassCreados{
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.botoneliminar {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    cursor: pointer;
}
#PaginacionI #Paginacion2{
    margin-top: 5px;
    width: 100%;
    height: 40px;
    flex-flow: row;
    display: flex;
    justify-content: center;
    align-items: center;
}
.titulo {
    background-color: #444140;
    color: rgb(31, 24, 24);
    border-radius: 10px 10px 0 0;
    grid-row: 1/2;
    width: calc(100% + 1px);
    grid-column: 1/4;
    min-height: max-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

#imgclases {
    width: 30vw;
    height: 30vw;
    max-width: 50vh;
    max-height: 50vh;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 10px;
    border-radius: 50%;
}

#botonear {
    margin: 10px;
    display: flex;
    justify-content: space-around;
}

#botonear input {
    width: 45%;
    height: 30px;
    background-color: #FFA987;
    border: transparent;
}

#contid {
    margin: 10px;
    text-align: center;
}

#clasesP, #contidU {
    display: flex;
    width: 100%;
    justify-content: center;
    height: calc(60vh);
    overflow: hidden;
    overflow-y: scroll;
}

#ClasesP, #ClasesC {
    background-color: white !important;
    border: 1px solid #7c7c7c !important;
}

#ClasesP:hover, #ClasesC:hover {
    color: white;
    background-color: #444140 !important;
    border: 1px solid #444140 !important;
}

#clasesP::-webkit-scrollbar, #contidU::-webkit-scrollbar {
    background-color: #F6EBE9;
    width: 12px;
}

#clasesP::-webkit-scrollbar-thumb, #contidU::-webkit-scrollbar-thumb {
    background-color: #444140;
}

#clasesP2, #contidU2 {
    width: 90%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
}

#conclases, #infop {
    width: 50%;
    display: flex;
    flex-flow: column;
}

#infop input, #conclases input {
    width: 98%;
    height: 30px;
    background-color: #FFA987;
    border: transparent;
    margin-block-start: 10px;
    margin-block-end: 10px;
}

.cardsclas {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-template-rows: max-content max-content;
    margin-top: 10px;
    background-color: #7c7c7c;
    width: 90%;
    border: #7c7c7c 1px solid;
    border-radius: 10px;
    box-shadow: 5px 5px 10px black;
}

.cardsclas input {
    width: 35px !important;
    height: 35px !important;
    background-color: white !important;
    border: transparent;
}

.cardsclas input:hover {
    background-color: #7c7c7c !important;
}
.Modal1ClasesR {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: grid;
    background-color: rgb(0, 0, 0, 0.8);
    grid-template-columns: 12.5% 35% 5% 35% 12.5%;
    grid-template-rows: 15% max-content 15%;
}

.PopUp2_ {
    grid-column: 2/3;
    grid-row: 2/3;
}

.PopUp1_ {
    grid-column: 4/5;
    grid-row: 2/3;
}

.PopUp1, .PopUp2 {
    display: flex;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgb(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
}

.inputCrearClase {
    width: 100%;
    height: 35px;
}
.inputCrearClase3{
    width: calc(100%);
    height: 35px;
}
.inputCrearClase2 {
    margin-left: 5px;
    height: 49px;
    width: 49px;
    background-color: white;
    background-image: url(/Images/Upload.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

.inputCrearClase2_ {
    margin-left: 5px;
    height: 39px;
    width: 49px;
    background-color: white;
    background-image: url(/Images/Upload.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

.inputCrearClase2:hover, .inputCrearClase2_:hover {
    background-color: #444140;
}

.GroupC {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 80%;
}

.GroupC2 {
    display: flex;
    width: 80% !important;
}

.Group {
    margin: 0;
}

#XimageCrearClase {
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    grid-row: 3/4;
    grid-column: 1/3;
}

.holaClase {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.CositaCambiar{
    display: flex;
    width: 100%;
}
.BotonMadreClase {
    background-color: #f6ebe9;
    height: 30px;
    width: 200px;
    margin: 0 10px 0 10px;
}
.selectCrearClaseOption{
    width: 100%;
    height: 35px;
}
#PopUpPart1 {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    grid-row: 2/3;
    grid-column: 1/2;
}

#PopUpPart2 {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: 2/3;
    grid-column: 2/3;
    padding: 5px;
}

.Etiqueta {
    margin: 2px;
    padding: 2px 10px 2px 10px;
    width: max-content;
    height: 20px;
    border-radius: 10px;
    font-size: 10px;
    background-color: #ffd166;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContainerPopUp2 {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 10px;
}

.PopUp1_ {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: max-content 1fr max-content max-content;
    border-radius: 10px;
    background-color: #7c7c7c;
    width: 100%;
    height: 100%;
}

.PopUp2_ {
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 10px;
    background-color: #7c7c7c;
    width: 100%;
    height: max-content;
    height: 100%;
}

.infoContainerModalClass {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 270px;
    align-items: center;
}

.infoContainerModalClass2 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    width: calc(70% - 20px);
    height: 230px;
    background-color: #f6ebe9;
}

.titleModal1ClasH2 {
    margin: 10px 0 10px 0;
}

.titleModal1Class {
    display: flex;
    justify-content: center;
    border-radius: 10px 10px 0 0;
    width: 100%;
    background-color: #444140;
    color: white;
}

.titleModal1Class2 {
    grid-row: 1/2;
    grid-column: 1/3;
}

#UsuariosIN {
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    width: 90%;
    height: 80%;
    background-color: #f6ebe9;
    border-radius: 10px;
    border: 1px solid #444140;
    overflow-y: scroll;
}
#UsuariosIN::-webkit-scrollbar{
    background-color:  #f6ebe9;
    width: 15px;
}
#UsuariosIN::-webkit-scrollbar-thumb{
    background-color: #444140;
    box-shadow: 2px 0 10px black;
}
.bmargintop {
    margin-top: 10px;
}

.GroupC3 {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    width: 100% !important;
}

.InfoClassCard {
    display: flex;
    width: 50%;
    height: 50px;
    justify-content: center;
    align-items: center;
}

.InfoClassCardConte {
    margin: 0 5px 0 5px;
}

.buscadorClases {
    align-items: center;
    margin-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 1fr;
}

.filtroClasesSearch {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-column: 2/3;
    grid-row: 1/2;
}

.filtroClasesSearch2 {
    border-radius: 10px;
    background-color: #444140;
    width: 100%;
    display: flex;
    justify-content: center;
}

.BotonMore {
    margin-left: 10px;
    display: flex;
    justify-content: flex-start;
    grid-column: 3/4;
    grid-row: 1/2;
}

.BotonMoreImage {
    cursor: pointer;
    width: 40px;
}

.buscadorClases2 {
    width: 50% !important;
}

.botoncard {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-column: 1/4;
    grid-row: 2/3 !important;
    align-items: center;
    flex-wrap: wrap;
}
@media(max-height: 600px){
    .Modal1ClasesR {
        height: max-content;
        grid-template-rows: 15% max-content 15%;
    }
}
@media(min-width:620px) {
    .DisCom {
        display: none;
    }
    .AparCom {
        display: grid !important;
    }
    .AparCom2 {
        display: flex !important;
    }
}
@media(max-width:920px){
    .Modal1ClasesR {
        grid-template-columns: 0.5% 48% 1% 48% 0.5%;
    }
}
@media(max-width:620px) {
    .ContainerPopUp2 {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 100%;
    }
    .BotonFlechita {
        border-radius: 10px;
        width: 40px;
        height: 40px;
    }
    .BotonFlechita:hover {
        background-color: #7c7c7c;
    }
    #DisPrimero {
        display: none;
    }
    .BotonesMoverCel {
        color: white;
        justify-content: space-around;
        align-items: center;
        display: flex;
        width: 90%;
        background-color: #444140;
        height: 50px;
        border-radius: 10px;
        margin-bottom: 20px;
    }
    #UsuariosIN {
        height: 60%;
    }
    .Modal1ClasesR {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        height: 100vh;
    }
    .BotonMore {
        margin-left: 0px;
        margin-right: 10px;
    }
    .filtroClasesSearch2 {
        width: 95%;
    }
    .buscadorClases {
        align-items: center;
        justify-content: space-between;
        display: flex;
    }
    .BotonMoreImage {
        width: 35px;
    }
    .DisCel {
        display: none;
    }
    .PopUp2_ {
        display: none;
        width: 90%;
        height: 80%;
    }
    .PopUp1_ {
        grid-template-columns: 100%;
        grid-template-rows: max-content 1fr max-content;
        width: 90%;
        height: 80%;
    }
    #XimageCrearClase {
        grid-row: 3/4;
        grid-column: 1/2;
    }
    #PopUpPart1 {
        grid-row: 1/2;
        grid-column: 1/2;
    }
    #PopUpPart2 {
        grid-row: 2/3;
        grid-column: 1/2;
    }
    #clasesP, #contidU {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        height: max-content;
        padding-bottom: 10px;
        max-height: 600px;
    }
    #infop, #conclases {
        width: 100%;
    }
    .franja {
        flex-flow: column;
        align-items: center;
    }
    #ClasesC, #ClasesP {
        height: 35px;
    }
    #imgclases {
        width: 40vw;
        height: 40vw;
        background-size: 100%;
        margin: 10px;
    }
    .TitleCardClase {
        width: 90%;
        text-align: left;
        overflow: hidden;
    }
    .cardsclas input {
        margin-right: 10px;
    }
    .cardsclas {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: max-content max-content;
        width: 88vw;
    }
    .InfoClassCard {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 60%;
    }
    .titulo {
        grid-row: 1/2;
        grid-column: 1/2;
    }
    .FechaCClase {
        width: 30%;
    }
    .botoncard {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row: 2/3;
        grid-column: 1/2;
    }
    .UsuarioInfoCardAbajo {
        text-align: center;
        grid-row: 2/3;
        grid-column: 1/3;
        margin-bottom: 10px;
    }
}