#Footer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #444140;
    color: white;
    padding: 5px;
    height: 15px;
    width: calc(100% - 10px);
}
@media(max-width: 350px){
    #Footer{
        height: 35px;
    }
}