#Header3Container {
    padding: 5px;
    display: grid;
    grid-template-columns: 15% 70% 15%;
    justify-content: center;
    background-color: #444140;
}
.formGames{
    margin-top: 5px;
    margin-bottom: 5px;
    box-sizing: border-box;
    border: 0;
    width: 99%;   
    min-height: calc(100vh - 70px);
    height: max-content;
}
.texto234{
    text-align: center;
    margin: 10px;
}
.title23{
    width: calc(100% - 30px);
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.formGamesalert{
    margin-left: 5px;
    margin-right: 5px;
    box-sizing: border-box;
    border: 0;
    width: calc(100% - 10px);   
    min-height: calc(100vh - 60px);
    height: max-content;
}
.formGames2{
    border: 0;
    width: 100%;
    height: 98%;
}
#Main4Container {
    grid-gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    justify-content: center;
    display: grid;
    min-height: calc(100vh - 100px);
    grid-template-columns: 40% 40%;
    grid-template-rows: max-content max-content;
}
#TeoriaCurso, #DidaCurso, #ExaCurso{
    padding: 10px;
    background-color: #F6EBE9;
    border-radius: 5px;
}
#TeoriaCurso {
    min-height: calc(100vh - 206px);
    grid-row: 1/2;
    grid-column: 1/3;
}

#DidaCurso {
    cursor: initial;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    grid-row: 2/3;
    grid-column: 1/2;
}
.incrustado{
    width: 40px;
    height: 40px;
    cursor: pointer;
    filter: invert(1);
}
#ExaCurso {
    cursor: initial;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    grid-row: 2/3;
    grid-column: 2/3;
}

#TitleCurso {
    grid-column: 2/3;
    color: white;
    margin-left: 4px;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    justify-content: center;
    min-height: 45px;
    display: flex;
    align-items: center;
}
.linkAtrasCurso{
    display: flex;
    justify-content: center;
}
#AtrasCurso {
    display: flex;
    align-items: center;
    grid-column: 1/2;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-position-x: center;
    background-position-y: center;
    background-size: 100%;
    background-image: url(/Images/Atrás.png);
}

.GuardarCurso {
    margin-right: 2px;
    height: 35px;
    width: 20%;
}
.GuardarCurso2 {
    height: 100%;
    width: 100%;
}

.LinkCursoMain4{
    width: 60%;
    height: 35px;
}

@media(max-width:720px){
    #Header3Container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    #Main4Container {
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content max-content;
    }
   
    #TeoriaCurso {
        grid-row: 1/2;
        grid-column: 1/2;
    }
    
    #DidaCurso {
        grid-row: 2/3;
        grid-column: 1/2;
    }
    
    #ExaCurso {
        grid-row: 3/4;
        grid-column: 1/2;
    }
}