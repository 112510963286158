#gridP {
    display: grid;
    grid-template-rows: max-content max-content;
    grid-template-columns: 30% 70%;
}

#HP {
    grid-row: 1/2;
    grid-column: 1/3;
}

#MP {
    grid-row: 2/3;
    grid-column: 2/3;
}

#MeP {
    grid-row: 2/3;
    grid-column: 1/2;
}

#menu {
    background-image: url(https://image.flaticon.com/icons/svg/56/56763.svg);
    background-size: 100% 100%;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    width: 10vw;
    height: 10vw;
    max-width: 8vh;
    max-height: 8vh;
    padding: 0px;
    transition: all 500ms;
    cursor: pointer;
}

#menu :hover {
    background-size: 90% 90%;
}

#Header2Container {
    display: flex;
    padding: 1vw;
    justify-content: space-between;
    align-items: center;
    background-color: #444140;
    width: calc(100% - 2vw);
    /*bendito sea el calc*/
    height: max-content;
}
.Campana:hover .CositoCampana{
   width: 35%;
   height: 30%;
   font-size: 12px;
   position: relative;
   left: 10%;
}
#logo {
    background-image: url(https://1.bp.blogspot.com/-4AYfdW1HnGQ/X02wnk_2J_I/AAAAAAAAPPk/znnHlLxw_bINf8jIvcaE3hxEruVJOjcawCLcBGAsYHQ/s16000/Logo.png);
    background-size: 100% 100%;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    padding: 0px;
    transition: all 500ms;
    width: 40vw;
    height: 20vw;
    max-width: 20vh;
    max-height: 10vh;
}
.CositoCampana{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 30%;
    background-color: #d83535;
    border-radius: 20%;
}
.LInKNotificaciones{
    text-decoration: none;
}
.CositoCampana p{
    margin: 0;
    color: black;
}
.ContenedorH2NotiPerfil{
    display: flex;
    height: 100%;
    align-items: center;
}
#profile, .Campana{
    margin: 0 10px 0 10px;
}
#profile {
    border-radius: 100%;
    background-size: 100%;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    padding: 0px;
    height: calc(20vw - 4px);
    /*bendito sea el calc*/
    width: calc(20vw - 4px);
    /*bendito sea el calc*/
    max-width: calc(10vh - 4px);
    /*bendito sea el calc*/
    max-height: calc(10vh - 4px);
    /*bendito sea el calc*/
    cursor: pointer;
    box-shadow: 5px 5px 15px black;
}

.Campana {
    border-radius: 100%;
    box-shadow: 5px 5px 15px black;
    background-image: url(/Images/Notificaciones0.png);
    background-size: 100%;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    padding: 0px;
    height: calc(20vw - 4px);
    /*bendito sea el calc*/
    width: calc(20vw - 4px);
    /*bendito sea el calc*/
    max-width: calc(10vh - 4px);
    /*bendito sea el calc*/
    max-height: calc(10vh - 4px);
    /*bendito sea el calc*/
    cursor: pointer;
}

#profile:hover, .Campana:hover {
    background-size: 80% 80%;
    box-shadow: 0px 0px 0px;
}