.juegoF {
    min-height: calc(100vh - 100px);
    width: calc(100% - 20px);
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    text-align: center;
    background-color: #f6EBE9;
    align-items: center;
    margin: 10px;
    box-sizing: border-box;
}
.ModalJuego{
    width: 50%;
    height: max-content;
    box-sizing: border-box;
    background-color: #7c7c7c;
    border-radius: 10px;
}
.ModalJuegoTitle{
    border-radius: 10px 10px 0 0;
    background-color: #444140;
    color: white;
    padding: 10px 10px 0 10px;
}
.cont_pregu {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    background-color: #7c7c7c;
}

.pregunta {
    margin: 0;
    font-size: 23px;
    padding: 10px;
    width: 69vw;
}
.opcionesJ {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 2vw;
    justify-content: space-around;
    width: 95%;
    background-color: rgb(100, 100, 100, 0.4);
    border-radius: 15px;
    margin-bottom: 10px;
}

.botonJ {
    display: flex;
    font-size: 20px;
    border: none;
    box-shadow: 2px 4px 10px black ;
    background-color: #444140;
    color: white;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-width: 250px;
    margin: 1.8vw;
    height: 60px;
    width: 45%;
    padding: 5px;
    cursor: pointer;
    transition: all 1000ms;
}
.botonJ:hover{
    box-shadow: 4px 8px 20px black ;
    background-color: #7c7c7c;
}
.botonV{
    border: none;
    box-shadow: 2px 4px 10px black ;
    border-radius: 10px;
    padding: 1vw;
    width: 90%;
    font-size: 25px;
    color:black;
    cursor: pointer;
    margin-bottom: 20px;
    transition: all 1000ms;
}
.botonV:hover{
    box-shadow: 4px 8px 20px black ;
    background-color: pink;
}
.botonJ:focus , .botonV:focus{    
    outline: none;
}
.Acabar{
    padding: 15px;
}
@media(max-width:620px) {
    .ModalJuego{
        width: 95%;
    }
    .botonJ {
        width: 90%;
    }
    .cont_pregu {
        width: 80%;
        text-align: justify;
        margin: 10px 0 10px 0;
        
    font-size: 16px;
    }
}