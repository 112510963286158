/*MAIN COMPUTADOR*/

#Main1Container {
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    padding: 2vw;
    width: 96vw;
    min-height: calc(100vh - (6vw + 125px));
}

#GridComp1, #GridComp2 {
    width: 70%;
    border-radius: 10px;
    justify-self: center;
    padding: 10px;
    background-color: #7c7c7c;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - (6vw + 145px));
}

#GridComp1 {
    grid-column: 1/2;
    grid-row: 1/2;
}

#GridComp2 {
    flex-flow: column;
    grid-column: 2/3;
    grid-row: 1/2;
}
#GridCom2Div3{
    display: none;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column;
    width: 100%;
}
#GridCom2Div3_{
    width: 80%;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
}
#LogoInicial{
    width: 100%;
}
.GridCom2Div1, #GridCom2Div2, .GridCom2Div1_{
    width: 100%;
}

.GridCom2Div1 {
    display: flex;
    justify-content: center;
}
.GridCom2Div1T{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}
.GridCom2Div1T p {
    font-size: 10px;
    color: red;
    margin: 0;
}
#GridCom2Div2,.GridCom2Div1__ {
    display: none;
}
#PInfo {
    text-align: justify;
}

.B1 {
    height: 30px;
    margin: 5px;
    width: calc(50% - 10px);
}

.B2 {
    height: 32px;
    margin: 5px;
    width: calc(50% - 10px);
}

.B3 {
    height: 30px;
    margin: 5px;
    width: calc(50% - 10px);
}

.B4 {
    margin: 2px;
    height: 30px;
    width: 100%;
}

.GridCom2Div1_ {
    margin-top: 25px;
}

#TitleReg {
    margin: 20px;
}

.GridCom2Div1_ {
    margin-top: 25px;
}
#Ximage{
    cursor: pointer;
    width: 15px;
}
#XimageC{
    width: 100%;
    display: flex;
    justify-content: left;
}
#RegistroCel,#RegistroCel2, #InicioCel, #InicioCel2{
display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator{
    cursor: pointer;
}
/*MEDIAS ALTURA*/

@media(max-height:550px) {
    #TitleReg {
        margin: 0px;
    }
    .GridCom2Div1_ {
        margin-top: 10px;
    }
}

@media(max-height:550px) {
    #TitleReg {
        margin: 15px;
    }
    .GridCom2Div1_ {
        margin-top: 25px;
    }
    .B4 {
        height: 25px;
    }
}


/*MAIN CELULAR*/

@media(max-width: 720px) {
    #RegistroCel, #InicioCel{
        background-color: rgb(0, 0, 0, 0.9);
        top:0;
        left: 0;
        position: fixed;
        display: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    #RegistroCel2, #InicioCel2{
        display: flex;
        flex-flow: column;
        padding: 10px;
        justify-content: space-around;
        align-items: center;
        background-color: white;
        border-radius: 10px;
        width: 90%;
        height: 90%;
    }
    
    #Main1Container {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows:max-content max-content;
        padding: 2vw;
        width: 96vw;
        min-height: calc(100vh - (25px + 30.875vw));
    }
    .GridCom2Div1__{
        display: block;
        grid-column: 1/2;
        grid-row: 2/3;
        width: 100%;
    }
    #GridComp1 {
        height: max-content;
        width: 80%;
        grid-column: 1/2;
        grid-row: 1/2;
    }
    #GridComp2 {
        display: none;
    }

    @media(max-height:500px) {
        #TitleReg {
            margin: 15px;
        }
        .B4 {
            height: 25px;
        }
        .GridCom2Div1_ {
            margin-top: 25px;
        }
        #Main1Container {
            height: max-content;
        }
        #GridComp1, #GridComp2 {
            height: max-content;
        }
    }
}

/*MEDIAS OPCIONALES*/

@media(max-width:1160px) {
    #GridComp1 {
        font-size: 15px;
    }
}

@media(max-width:990px) {
    #GridComp1 {
        width: 80%;
    }
}

@media(max-width:840px) {
    #GridComp1 {
        width: 90%;
    }
    .B1 {
        height: 30px;
        margin: 2px;
        width: calc(50% - 4px);
        font-size: 12px;
    }
    .B2 {
        height: 35px;
        margin: 2px;
        width: calc(51% - 4px);
        font-size: 12px;
    }
    .B3 {
        font-size: 12px;
        height: 30px;
        margin: 2px;
        width: calc(49% - 4px);
    }
}

@media(max-width:800px) {
    #GridComp1 {
        width: 95%;
    }
}

@media(max-width:320px) {
    #TitleReg {
        margin: 15px;
    }
    .B4 {
        height: 25px;
    }
    .GridCom2Div1_ {
        margin-top: 25px;
    }
    #Main1Container {
        height: max-content;
    }
    #GridComp1, #GridComp2 {
        height: max-content;
    }
}