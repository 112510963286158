.LogoCrearCurso {
    width: 30vh;
}

.MainCrearCurso {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 100%;
}
.TitlesI{
    overflow: hidden;
    width: 90%;
}
.PadreCursoCrear {
    width: 100%;
    min-height: calc(100vh - 71px);
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 100%;
}

.EstiloButtonCrearCursoC {
    width: 180px;
    height: 30px;
    margin-top: 20px;
}

#AlertasCrearCurso {
    margin: 0;
}

#AlertasCrearCursoDiv {
    border-radius: 10px;
    display: none;
    justify-content: center;
    width: 80%;
    background-color: white;
    padding: 1px;
}

.CenterCrearC {
    display: flex;
    flex-flow: column;
    align-items: center;
}
.Porciento100{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Porciento100IMG, .Porciento100IMG2{
    width: 50%;
}
.Porciento1002{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 206px);
}
.EstiloButtonCrearCursoC:hover {
    background-color: #444140;
    color: white;
    transition: all 700ms;
}

.imagenEditCrearC {
    width: 150px;
    height: 150px;
}

.EncabezadoCrearC {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-around;
    flex-wrap: wrap;
}

.BotonesContaCrearC {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ButtonMetodosCrearC {
    width: 40px;
    height: 40px;
    background-color: white;
    cursor: pointer;
    border: 0;
    padding: 5px;
}

.buttonAlgo {
    width: 100%;
    text-align: center;
}

.CardsCrearContenido {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 71px);
}

.CardCrearCursoContenido {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 5px 5px 10px black;
    border-radius: 5px;
    background-color: #7c7c7c;
    color: black;
    width: 200px;
    height: 200px;
}

.editarInfo {
    background-color: #7c7c7c;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    grid-column: 1/2;
    grid-row: 1/2;
}

.TableroCrearC {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    grid-row: 1/2;
    grid-column: 1/2;
    background-color: white;
}

.ButtonCCurso2 {
    display: flex;
    text-decoration: none;
    justify-content: center;
    width: 100%;
}

.ButtonCCurso {
    height: 35px;
    width: 80%;
}

.ButtonCCurso:hover, .ButonSubir:hover {
    background-color: #444140 !important;
    color: black;
}

.SubIMG {
    width: calc(100% - 40px);
    height: 35px;
}

.divSubImG {
    display: flex;
    align-items: center;
}

.XImageSubImage {
    background-image: url(/images/XImage.png);
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    width: 35px;
    height: 35px;
}

.XImageSubImage:hover {
    background-color: #444140 !important;
}

.SubIMG:focus {
    outline: none;
}

.SubIMG::-webkit-file-upload-button {
    background-image: url(/images/Upload.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    color: #F6EBE9;
    border-radius: 0;
    border: 0;
    width: 35px;
    height: 100%;
    background-color: #F6EBE9;
    transition: all 1000ms;
}

.SubIMG::-webkit-file-upload-button:hover {
    background-color: lightgray;
    color: lightgray;
    transition: all 1000ms;
}

.EditorCrearCurso {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.FormCurso, .OpcionesCrearCurso {
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
}

#Negrilla {
    background-image: url(/images/Negrilla.png);
}

#Cursiva {
    background-image: url(/images/Cursiva.png);
}

#Titulo {
    background-image: url(/images/Titulo.png);
}
.centerIMG{
    width: 100%;
    justify-content: center;
    display: flex;
}
.centerImg{
    width: 300px;
}
.ButonSubir {
    margin-top: 10px;
    height: 35px;
    margin-left: 10px;
    width: 100px;
}

.Cosa {
    width: 100px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.Cien2Ele {
    display: flex;
    justify-content: center;
    align-items: center;
}

.MiniButonCrear {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    width: 35px;
    height: 35px;
    background-color: white;
}

.MiniButonCrear1 {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    width: 35px;
    height: 35px;
    background-color: white;
}

.Cien {
    width: 80%;
}

.ICCUrso, .TextAreaCCurso, .TextAreaCCurso2 {
    border-radius: 5px;
    background-color: #F6EBE9;
}

.ICCUrso {
    width: 100%;
    height: 30px;
}

.ICCUrso:focus, .TextAreaCCurso, .TextAreaCCurso2{
    outline: none;
}
.ClassNameDivision{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.TextAreaCCurso {
    width: 100%;
    height: 40px;
}
.TextAreaCCurso2{
    width: 77%;
    height: 40px;
}
.BotonSubirContenidoE{
    width: 60px;
    height: 30px;
    background-color:#FFA988;
}
.BotonSubirContenidoE:hover{
    background-color: #7c7c7c;
    color: black;
}
.CantPreguntasBoton{
    width: 60%;
    height: 35px;
}
.DivCantidadPreguntas2{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.DivCantidadPreguntas{
    width: 100%;
    display: flex;
    justify-content: center;
}
.InfoCrearC p {
    color: white;
}

.InfoCrearC {
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: #444140;
    width: 100%;
    height: 100%;
    grid-row: 1/2;
    grid-column: 2/3;
    justify-content: space-around;
}

.cardcontenidoTeorico {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.contenido {
    width: calc(100% - 62px);
}

.basuraCrearCT {
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.hoverCreadorT:hover {
    background-color: #7c7c7c !important;
    color: black !important;
}

.MainTablero {
    overflow: hidden;
    overflow-y: scroll;
    background-color: #F6EBE9;
    width: 95%;
    height: 90%;
}

#tablero2, #tablero1 {
    box-sizing: border-box;
    padding: 10px;
}

.VistaPreviaMainTablero {
    display: none;
    background-color: #F6EBE9;
    width: 100%;
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.colorcitoReturnSelectCT {
    background-color: wheat !important;
}

.basuraCrearCTContainer {
    display: flex;
    justify-content: space-around;
}

.EditorSelectCrearCT {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 5px 0 5px 0;
    width: 95%;
    background-color: #444140;
}

.MainTablero::-webkit-scrollbar {
    background-color: #F6EBE9;
    width: 15px;
}

.MainTablero::-webkit-scrollbar-thumb {
    background-color: #444140;
    box-shadow: 2px 0 10px black;
}

.BotonSelectCrearCurso {
    width: 40%;
    height: 30px;
    margin: 0 10px 0 10px;
}

.BotonSelectCrearCurso:hover {
    color: black;
    background-color: #7c7c7c !important;
}

.flex-wrap {
    align-items: flex-end;
    flex-wrap: wrap;
}

.ReanudarCursoTeorico{
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
}
.MasCrearContenidoT2{
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: max-content;
}
.CancelarElHueco{
    cursor: pointer;
    width: 30px;
}
.elHueco{
    box-sizing: border-box;
    padding: 0 5px 0 5px;
    grid-column: 1/13;
    display: none;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    background-color:#7c7c7c;
    border-radius: 10px;
}
.MasCrearContenidoT{
    width: 20px;
    position: relative;
    top: -7px;
    grid-column: 7/8;
    grid-row: 1/2;
    cursor: pointer;
}
.RayaCrearContenidoT{
    width: 100%;
    height: 4px;
    background-color: #444140;
    grid-column: 1/13;
    grid-row: 1/2;
}
@media(max-width: 600px) {
    .BotonSubirContenidoE{
       margin-left: 5px;
    }
    .Porciento100IMG2{
        width: 90%;
    }
    .centerImg{
        width: 90%;
    }
    .EditorSelectCrearCT {
        margin: 10px 0 10px 0;
    }
    .CardsCrearContenido {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-flow: column;
        width: 100%;
        min-height: 700px;
    }
    .editarInfo {
        grid-column: 1/2;
        grid-row: 1/2;
    }
    .PadreCursoCrear {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 570px max-content;
    }
    .MainCrearCurso {
        grid-template-columns: 100%;
        grid-template-rows: 650px max-content;
    }
    .contenido{
        width: calc(100% - 35px);
        text-align: justify;
        margin-right: 10px;
    }
    .basuraCrearCTContainer {
        flex-flow: column;
        width: 35px;
    }
    .basuraCrearCT{
        margin: 10px 0 0 0;
    }
    .InfoCrearC {
        grid-row: 1/2;
        grid-column: 1/2;
    }
    .TableroCrearC {
        min-height: 500px;
        grid-row: 2/3;
        grid-column: 1/2;
    }
    .MainTablero {
        overflow-y: hidden;
        width: 95%;
        height: 97%;
    }
}