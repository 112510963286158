#Main6Container{
    margin:10px 0 10px 0;
    width: 100%;
    justify-content: center;
    display: grid;
    grid-template-columns: 40% 40%;
    grid-template-rows: 1fr;
    grid-gap: 10px;
}
#Main6I,#Main6C{
    background-color: orange;
    height:100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #F6EBE9;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: scroll;
}
.BClaseACCionC{
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
}

#Main6I::-webkit-scrollbar, #Main6C::-webkit-scrollbar{
    background-color:  #F6EBE9;
    width: 15px;
}
#Main6I::-webkit-scrollbar-thumb,  #Main6C::-webkit-scrollbar-thumb{
    background-color: #444140;
    box-shadow: 2px 0 10px black;
}


.ButtonMisCursosC{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
}
.ButtonMisCursosC2{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#Main6I{
    grid-row: 1/2;
    grid-column: 1/2;
}
.ButtonMisCursosC{
    width: 100%;
    text-decoration: none;
}
#Main6C{
    grid-row: 1/2;
    grid-column: 2/3;
}
.buttonMisCursos{
    margin: 5px;
    width: calc(100% - 10px);
    height: 35px !important;
}
#CursosI2{
    display: none;
}
.buttonMisCursos2{
    margin-top: 10px;
    width: calc(100% - 80px);
    height: 35px !important;
}
.Flechita30{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    border-radius: 5px;
    height: 35px;
    background-color: white;
    margin: 0 5px 0 5px;
    border: 1px solid #444140;
    cursor: pointer;
}
.Flechita30 p{
    margin: 0px;
}
.Flechita30:hover{
    background-color: #444140;
    color: white;
}
.jodeteLink{
    width: 100%;
}
#CardsInner,#CardsInner2{
    width: 90%;
    height: 100%;
}
.CardCIMisC{
    margin: 10px;
    padding: 5px;
    width: calc(100% - 30px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color:  #7c7c7c;
    border-radius: 5px;
    box-shadow: 5px 5px 10px black;
}
.marignC2{
    width: calc(100% - 36px);
    height: max-content;
}
.marignO{
    margin: 2px;
}
.ReanudarC{
    width: 35px;
    height: 35px;
}
.CursoIC24{
    width: 70% !important;
}
.Reanudar{
    background-image: url(/Images/Play.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y:center;
    background-size: 30px;
    width: 30;
    height: 30px;
}
.CIMB{
    margin-bottom: 10px;
}
@media(max-width: 880px){
    #Main6Container{
        
    grid-template-columns: 49% 49%;
    }
}
@media(min-width: 720px){
    #Main6Container{
        height: calc(90vh - 45px - 2vw);
    }
}
@media(max-width:720px){
.InfoContMini{
    width: 90% !important;
}
.CursoIC2{
    width: 55% !important;
}
    #Main6Container{
        min-height: calc(90vh - 45px - 2vw) !important;
        grid-template-columns: 95%;
        grid-template-rows: 500px 500px;
    }
    #Main6I{
        grid-row: 1/2;
        grid-column: 1/2;
    }
    #Main6C{
        grid-column: 1/2;
        grid-row: 2/3;
    }
}
