.CentradoCrearCurso{
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
}
.CentradoCrearCurso p{
    width: 80%;
    text-align: justify;
}
.Width100{
    width: 80%;
}
.textArea{
    box-sizing: border-box;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 0;
}
.textArea::-webkit-scrollbar{
    cursor: pointer;
    background-color:  #F6EBE9;
    width: 15px;
}
.textArea::-webkit-scrollbar-thumb{
 
    background-color: #444140;
}
.SubirContD{
    display: flex;
    width: 100;
    justify-content: space-around;
    align-items: center;
}
.SubirConteD{
    background-image:url(/Images/Upload.png) ;
    background-color: white;
    width: 37px;
    height: 37px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 1000ms;
    cursor: pointer;
}
.SubirConteD2{
    background-image:url(/Images/Basura.png) ;
    background-color: white;
    width: 37px;
    height: 37px;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 1000ms;
    cursor: pointer;
}
.SubirConteD:hover, .SubirConteD2:hover{
    background-color: #7c7c7c;
    transition: all 1000ms;
}
.MarginTop{
    margin-top: 30px;
}
.SubirConteD1{
    width: calc(100% - 95px);
    height: 35px;
}   
.textArea:focus{
    outline: none;
}
.MainTablero2{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6EBE9;
    width: 95%;
    height: 90%;
}
@media(max-width: 1150px){
    .TextLow{
        font-size: 14px;
    }
}@media(max-width: 870px){
    .TextLow{
        font-size: 12px;
    }
}@media(max-width: 600px){
    .MainTablero2{
        grid-column: 1/2;
        grid-row: 2/3;
        height: 100%;
    }
    .textArea{
        height: 100%;
    } 
    .TextLow{
        font-size: 1em;
    }
}